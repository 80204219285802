.quranList {
    display: flex;
}

.surahMain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.MainLine {
    display: flex;
    justify-content: space-between;
}

.secondLine {
    /* text-align: start; */
    display: flex;
    justify-content: space-between;
    /* margin-left: 1rem; */
    font-size: small;
}

.quranList div {
    /* padding-right: 0.5rem; */
    padding-left: 0.5rem;
}

.englishName,
.arabicName {
    font-weight: bold;
}