#Header {
    display: flex;
    justify-content: space-between;
    background-color: var(--subFrameColor);
    color: var(--BGDark);
    height: 4rem;
}

.title {
    cursor: pointer;
    text-decoration: none;
    color: rgb(56, 30, 30);
}

.title h1,
.title h5 {
    padding: 0;
    padding-left: 1rem;
    margin: 0;
}

.title h5 {
    font-size: small;
}

.nav {
    align-items: center;
    justify-content: center;
}

.nav ul {
    display: flex;
    list-style: none;
    border: none;
    background-color: inherit;
    cursor: pointer;
    align-items: center;
    padding: 0.5rem;
    margin: 0;
    height: 100%;
    /* padding-top: 1.5rem;
    padding-bottom: 1.5rem; */
    justify-content: center;
}

.nav li {
    padding: 1rem;
}

.nav a {
    text-decoration: none;
    color: black;
}