#Home {
    display: flex;
    justify-content: space-evenly;
    /* min-height: 35rem; */
    /* max-height: 88vh; */
}

.book {
    width: 100%;
    min-width: 350px;
    border: 0.1rem solid var(--BGDark);
    /* border-radius: 0.5rem; */
}

#dragbar {
    cursor: col-resize;
    border: 1px solid var(--subFrameColor);
}

/* .resize {
    cursor: col-resize;
    overflow: auto;
}

.resize.horizontal {
    resize: horizontal;
} */

.bookHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--mainFrameColor);
    color: var(--BGLight);
    padding: 0.5rem;
    height: 2.5rem;
}

.bookHeader div {
    display: flex;
}

.bookHeader button {
    color: var(--BGLight);
    background: inherit;
    border: none;
    padding: 0.3rem;
}

.bookHeader img {
    height: 1.5rem
}


.bookContent {
    /* display: flex; */
    /* padding-left: 2rem; */
    overflow-y: scroll;
    height: auto;
    max-height: 80vh;
    overflow: auto;
}

.item {
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: start;
}

.item::before {
    text-align: start;
    justify-content: start;
}

.itemAcc button {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}


.chapterDropdown button {
    padding: 0.3rem;
    margin: 0.3rem;
    border: 1px solid var(--BGDark);
    border-radius: 0.5rem;
    width: 3rem;

}

.chapterDropdown button:hover {
    background-color: var(--subFrameColor);
    color: var(--mainFrameColor);
}

/* css for mobile screen */
@media screen and (max-width: 700px) {
    #Home {
        flex-direction: column;
        /* min-height: 35rem; */
        /* max-height: 88vh; */
    }

    .bookContent {
        min-height: 10rem;
        height: 41vh;
    }

    .offcanvas {
        max-width: 80%;
    }
}