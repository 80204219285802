#surah {
    margin: 0.2rem;
    border: 1px solid var(--BGDark);
    border-radius: 0.5rem;
    min-height: 76vh;
}

#surahHeader {
    background-color: var(--subFrameColor);
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#surahHeader div {
    display: flex;
}

#surahHeader button {
    background-color: inherit;
    border-radius: 0.5rem;
    border: none;
}

#surahHeader img {
    width: 2rem;
    height: 2rem;
}

.surahVerse {
    display: flex;
    justify-content: start;
}

.verseNumber {
    display: inline;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.verse {
    width: 100%;
    padding: 0.5rem;
}

.verseArabic {
    text-align: end;
    font-weight: bold;
}

.verseTranslation {
    text-align: start;

}