#quranSearch {
    align-items: center;
}

.searchForm input {
    width: 8rem;
    border-radius: 1rem;
}

#searchResults {
    height: min-content;
}


#searchResults button {
    height: min-content;
    padding: 0;
}