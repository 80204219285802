.App,
* {
  text-align: center;
  margin: 0;
  padding: 0;
}

.App {
  min-width: 350px;
}

a {
  text-decoration: none;
  color: black;
}

ul,
li {
  list-style: none;
}