#quranOffcanvas * {
    text-align: start;
}

.searchVerse {
    text-decoration: none;
    color: black;
    /* display: inline; */
    text-align: start;
}

.verseId {
    font-weight: bold;
    padding-right: 1rem;
}

.verse {
    text-align: start;
}