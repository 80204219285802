.searchChapterVerse {
    text-align: left;
    border: none;
    background-color: inherit;
}

.searchVerseNumber,
.searchVerse {
    display: inline;
}

.searchVerseNumber {
    font-weight: bold;
    padding-right: 1rem;
}

.searchFooter {
    display: flex;
    justify-content: space-between;
}

.searchFooter p {
    padding: 0;
    margin: 0;
}

.searchFooter button {
    border: none;
}