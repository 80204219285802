#chapter {
    margin: 0.2rem;
    border: 1px solid var(--BGDark);
    border-radius: 0.5rem;
    min-height: 76vh;
}

#chapterHeader {
    background-color: var(--subFrameColor);
    border-radius: 0.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#chapterHeader button {
    background-color: inherit;
    border-radius: 0.5rem;
    border: none;
}

#chapterHeader img {
    width: 2rem;
    height: 2rem;
}

.chapterVerse {
    display: flex;
    justify-content: start;
}

.verse {
    text-align: start;
    padding: 0;
}