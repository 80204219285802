.bibleList {
    /* text-align: start; */
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.bibleList div {
    padding-right: 1.5rem;
    padding-left: 0.5rem;
}

.bookName {
    font-weight: bold;
}

.totalChapters {
    text-align: end;
}

.dropDownHeader {
    border: 0;
    width: 100%;
    height: 100%;
    background-color: inherit;
}